import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../../components';
import {
  composeValidators,
  emailFormatValid,
  maxLength,
  minLength,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  required,
  requiredStringNoTrim,
} from '../../../util/validators';

import css from './SignupForm.module.css';

const SignupFormComponent = props => {
  const { rootClassName, className, intl, ...rest } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const messages = useMemo(
    () => ({
      userTypeLabel: intl.formatMessage({ id: 'PayoutDetailsForm.accountTypeTitle' }),
      userTypePlaceholder: intl.formatMessage({ id: 'General.accountTypePlaceholder' }),
      userTypeIndividual: intl.formatMessage({ id: 'General.individual' }),
      userTypeCompany: intl.formatMessage({ id: 'General.accountTypeCompany' }),
      emailLabel: intl.formatMessage({ id: 'SignupForm.emailLabel' }),
      emailPlaceholder: intl.formatMessage({ id: 'SignupForm.emailPlaceholder' }),
      emailRequired: intl.formatMessage({ id: 'SignupForm.emailRequired' }),
      emailInvalid: intl.formatMessage({ id: 'SignupForm.emailInvalid' }),
      passwordLabel: intl.formatMessage({ id: 'SignupForm.passwordLabel' }),
      passwordPlaceholder: intl.formatMessage({ id: 'SignupForm.passwordPlaceholder' }),
      passwordRequired: intl.formatMessage({ id: 'SignupForm.passwordRequired' }),
      passwordTooShort: intl.formatMessage(
        { id: 'SignupForm.passwordTooShort' },
        { minLength: PASSWORD_MIN_LENGTH }
      ),
      passwordTooLong: intl.formatMessage(
        { id: 'SignupForm.passwordTooLong' },
        { maxLength: PASSWORD_MAX_LENGTH }
      ),
      firstNameLabel: intl.formatMessage({ id: 'SignupForm.firstNameLabel' }),
      firstNamePlaceholder: intl.formatMessage({ id: 'General.firstNamePlaceholder' }),
      firstNameRequired: intl.formatMessage({ id: 'SignupForm.firstNameRequired' }),
      lastNameLabel: intl.formatMessage({ id: 'SignupForm.lastNameLabel' }),
      lastNamePlaceholder: intl.formatMessage({ id: 'General.lastNamePlaceholder' }),
      lastNameRequired: intl.formatMessage({ id: 'SignupForm.lastNameRequired' }),
      companyNameLabel: intl.formatMessage({ id: 'General.companyNameLabel' }),
      companyNamePlaceholder: intl.formatMessage({ id: 'General.companyNamePlaceholder' }),
      companyNameRequired: intl.formatMessage({ id: 'General.companyNameRequired' }),
      companyTypeLabel: intl.formatMessage({ id: 'General.companyTypeLabel' }),
      companyTypePlaceholder: intl.formatMessage({ id: 'General.companyTypePlaceholder' }),
      companyTypeRequired: intl.formatMessage({ id: 'General.companyTypeRequired' }),
      signUp: intl.formatMessage({ id: 'SignupForm.signUp' }),
    }),
    [intl]
  );

  return (
    <FinalForm
      {...rest}
      render={fieldRenderProps => {
        const { formId, handleSubmit, inProgress, invalid, values, form } = fieldRenderProps;

        const submitDisabled = invalid || inProgress;
        const isUserTypeCompany = values.isCompany === 'true';

        const resetFields = () => {
          form.change('fname', '');
          form.change('lname', '');
          form.change('cname', '');
          form.change('ctype', '');
          form.change('password', '');
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.fields}>
              <FieldSelect
                id="isCompany"
                name="isCompany"
                label={messages.userTypeLabel}
                onChange={resetFields}
              >
                <option disabled value="">
                  {messages.userTypePlaceholder}
                </option>

                <option value="false">{messages.userTypeIndividual}</option>
                <option value="true">{messages.userTypeCompany}</option>
              </FieldSelect>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={messages.emailLabel}
                placeholder={messages.emailPlaceholder}
                validate={composeValidators(
                  required(messages.emailRequired),
                  emailFormatValid(messages.emailInvalid)
                )}
              />
              {isUserTypeCompany ? (
                <>
                  <FieldTextInput
                    className={css.formMargins}
                    type="text"
                    id={formId ? `${formId}.cname` : 'cname'}
                    name="cname"
                    label={messages.companyNameLabel}
                    placeholder={messages.companyNamePlaceholder}
                    validate={required(messages.companyNameRequired)}
                  />
                  <FieldTextInput
                    className={css.formMargins}
                    type="text"
                    id={formId ? `${formId}.ctype` : 'ctype'}
                    name="ctype"
                    label={messages.companyTypeLabel}
                    placeholder={messages.companyTypePlaceholder}
                    validate={required(messages.companyTypeRequired)}
                  />
                </>
              ) : (
                <>
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={messages.firstNameLabel}
                    placeholder={messages.firstNamePlaceholder}
                    validate={required(messages.firstNameRequired)}
                  />
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={messages.lastNameLabel}
                    placeholder={messages.lastNamePlaceholder}
                    validate={required(messages.lastNameRequired)}
                  />
                </>
              )}
              <FieldTextInput
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={messages.passwordLabel}
                placeholder={messages.passwordPlaceholder}
                validate={composeValidators(
                  requiredStringNoTrim(messages.passwordRequired),
                  minLength(messages.passwordTooShort, PASSWORD_MIN_LENGTH),
                  maxLength(messages.passwordTooLong, PASSWORD_MAX_LENGTH)
                )}
              />
            </div>
            <div className={css.bottomWrapper}>
              <PrimaryButton
                type="submit"
                className={css.modalButton}
                inProgress={inProgress}
                disabled={submitDisabled}
              >
                {messages.signUp}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = injectIntl(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
